.music {
  width: 100%;
}

.music h1 {
  margin: 1.5rem auto;
}

.music h2 {
  margin: 1rem auto;
}

#song-info {
  margin: 0 auto;
}

#song-audio-container {
  max-width: max-content;
  margin: 0 auto;
}

#song-audio {
  width: max-content;
  margin: 0 auto;
}

#song-comments-container {
  max-width: 45vw;
  margin: 0 auto;
}

#song-parts {
  margin-top: 2rem;
}

.musicHeader {
  color: var(--headers);
  font-size: 25px;
}

.musicTable {
  border-collapse: collapse;
  width: 100%;
}

.musicRow {
  border-bottom: 1px solid var(--navbar);
}

.musicEntry {
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faImage {
  margin-right: 5px;
}

.pdf {
  width: 99vw;
  height: 99vh;
  margin: 0 auto;
}

@media (max-width: 450px) {
  #song-audio {
    max-width: 90vw;
    width: 90vw;
  }
  
  #song-comments-container {
    max-width: 90vw;
  }
}
