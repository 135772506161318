ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#historyList {
  float: left;
  display: block;
  padding-right: 2%;
  padding-bottom: 1%;
}

.History p {
  padding-left: 1%;
}

.History h2 {
  padding-top: 1%;
  padding-bottom: 1%;
}

.History h3 {
  padding-top: 1%;
  padding-bottom: 1%;
}

#historyLink {
  text-decoration: none;
}

#historyLink:link, #historyLink:visited {
  color: var(--link);
}

.historyController {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
}
