#app {
  position: relative;
  min-height: 100%;
  min-width: fit-content;
}

.content-wrap {
  padding-bottom: 1px;
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background: var(--background);
  background-size: var(--background-blow-up);
  animation: var(--background-animation);
  color: var(--text);
  font: var(--global-font);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--headers)
}

button {
  color: var(--button-text);
  background: var(--button-background);
  border-radius: 5px;
  cursor: pointer;
  opacity: 85%;
  transition: 0.2s;
}

button:hover {
  background: var(--button-highlight);
}

.songLink {
  font-size: 20px;
  margin: 20px auto;
  text-decoration: none;
  color: var(--link)
}

.songLink.a:link, .songLink.a:visited {
  color: var(--link)
}

.songSearch {
  width: 300px;
  font-size: 18px;
  margin-top: 5px;
}

.faFile {
  color: var(--link);
  margin-right: 10px;
}

.documents-frame-docs {
  width: 100%;
  height: 400px;
  border: 0;
  filter: var(--calendar-filter);
}

.documents-frame-minutes {
  width: 100%;
  height: 600px;
  border: 0;
  filter: var(--calendar-filter);
}

.footer {
  width: 100%;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  height: max-content;
  background: var(--navbar);  
  background-size: var(--background-blow-up);
  animation: var(--background-animation);
  color: var(--button-text);
}

.footText {
  margin-top: 5px;
  margin-bottom: 5px;
}

.spongeButton {
  font-size: 28px;
  background-color: red;
  color: white;
  width: max-content;
}

.spongeButton:hover {
  cursor: pointer;
}

.meme {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.thonkTiger {
  height: 200px;
}

.rotate {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 100%;
	}

	100% {
		background-position: 100% 200%;
	}
}

@media (max-width: 950px) {
  .body {
    z-index: 1;
  }
  
  .content-wrap {
    width: 100vw;
  }

  .admin {
    overflow-y: auto;
  }
}

@media (min-width: 950px) {
  .body {
    padding: 0 2rem;
  }
}

@media (max-width: 450px) {
  .footer {
    margin-top: 1rem;
  }
}