.qdbTables {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

td.quotePerson {
  width: 300px;
  color: var(--text);
}

td.quote {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
}

tr.QDBTitle {
  background-color: var(--navbar);
  border-color: var(--navbar);
  color: var(--button-text)
}

tr.QDBRow {
  border: 1px solid var(--headers);
  border-color: var(--headers);
}

table.QDBTable {
  border-collapse: collapse;
  margin-bottom: 2%;
  margin-top: 25px;
  margin-right: 10px;
}

@media (min-width: 0px) {
  table.QDBTable {
    width: 300px;
  }
}

th.QDBApproval, td.QDBApproval {
  border-bottom: 1px solid gray;
  padding-bottom: 1%;
  padding-top: 1%;
}

table.QDBApproval {
  table-layout: auto;
  text-align: center;
  width: 100%;
  border-collapse: collapse;
}

tr.secondary {
  display: flex;
  justify-content: space-between;
  border-top: none;
  padding-top: 5px;
}

tr.quoteRow {
  border-bottom: none;
}

.qdbDelete {
  margin-top: 5px;
}

.quoteSearch {
  font-size: 16px;
  width: 300px;
  height: 25px;
  margin-top: 15px;
}

.searchTag {
  margin-right: 5px;
  font-size: 18px;
}

.faApprove {
  font-size: 20px;
  color: orange;
}

.quoteTextArea {
  width: 265px;
  height: 150px;
  resize: none;
  margin-left: 10px;
  font-size: 14px;
}

.multiline {
  white-space: pre-wrap;
}

code {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid orange;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.qdbInput {
  margin-left:.5em;
  margin-top: .5em;
  margin-bottom: .5em;
  font-size: 16px;
}
