#Home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.homedocs {
  width: 800px;
  height: 350px;
}

#Calendar {
  text-align: center;
}

#Songs h1 {
  margin: 1.5rem auto;
}

#Calendar h1 {
  margin: 1.5rem auto;
}

#calendarBlock {
  padding-top: 1rem;
  filter: var(--calendar-filter);
}

@media (max-width: 950px) {
  #Calendar iframe {
    width: 95vw;
    height: 600px;
  }

  #Songs {
    width: 90vw;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  #Songs h1 {
    margin: 0.5rem auto 1rem;
  }
  
  #Calendar h1 {
    margin: 0.5rem auto 1rem;
  }
}

@media (min-width: 950px) {
  #Calendar iframe{
    width: 700px;
    height: 675px;
  }
}