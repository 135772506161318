.electionRow:hover {
    cursor: pointer;
    background-color: orange;
  }

.resultsDiv {
    border: 2px solid var(--navbar);
    width: 500px;
    height: 250px;
    margin: 10px;
}

.resultsCandidateRow {
    border: 1px solid var(--navbar);
}